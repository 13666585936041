import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import {useHistory, Link} from 'react-router-dom';
import bannerImage from '../banner_image_120px.png';

const Banner=()=>{
  const history = useHistory();
  return(
  <Container fluid style={{backgroundColor: "#FFAEC9", margin: "0px"}}>
    <Container>
      <Link to="/"><Image src={bannerImage} roundedCircle style={{margin: "10px"}}/></Link>
    </Container>
  </Container>);
}

export default Banner;