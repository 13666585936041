import { 
  Container, 
} from "react-bootstrap";

import Navi from '../NavigationBar';
import Banner from '../Banner';
import Footer from '../Footer';

const Home=()=>{
  return (
    <div className="base">
      <Banner/>
      <Navi/>
      <Container style = {{marginTop: "50px"}}>
        <div className="title">
          <h1>Slothman's Corner</h1>
        </div>
      </Container>
      <Footer/>
    </div>
  );
}

export default Home;
