import {gql} from '@apollo/client'

export const CREATE_TYPE = gql`
  mutation CreateType($createTypeInput: TypeMutationInput!) {
    createType(input: $createTypeInput) {
      id
      name
    }
  }
`

export const UPDATE_TYPE = gql`
  mutation UpdateType($id: String!, $updateTypeInput: TypeMutationInput!) {
    updateType(id: $id, input: $updateTypeInput) {
      id
      name
    }
  }
`

export const DELETE_TYPE = gql`
  mutation DeleteType($id: String!){
    deleteType(id: $id)
  }
`