import {
  Container,
} from 'react-bootstrap';

const Footer=()=>{
  return(
    <>
      <div style={{
        display: "block",
        padding: "20px",
        height: "60px",
        width: "100%"}}></div>
      <Container fluid style={{
        backgroundColor: "#73707D",
        margin: "0px",
        position: "fixed",
        left: "0",
        bottom: "0"}}>
        <div className="d-flex justify-content-center" 
          style={{color: "white", padding: "20px"}}></div>
      </Container>
    </>
  )
}

export default Footer;