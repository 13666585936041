import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import { 
  Navbar, 
  Nav,
  NavDropdown,
  Col, Row,
} from "react-bootstrap";
import {
  Container,
} from 'react-bootstrap';
import {useAppSelector} from '../hooks';

const Navi=()=>{
  const auth = useAppSelector((state)=>state.auth);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openFiction, setOpenFiction] = useState(false);

  return (
    <Navbar bg="light" expand="md" className="border-dark border-2 border-top border-bottom" style={{padding: "1px"}}>
      <Container style={{fontSize: "18px"}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Row>
              <Col 
                onMouseEnter={()=>{setOpen(true)}}
                onMouseLeave={()=>{setOpen(false)}}>
                <NavDropdown 
                  id="basic-nav-dropdown"
                  title="Blogpost"
                  onClick={()=>{window.location.href=('/posts/Blogpost')}}
                  show={open}>
                  <NavDropdown.Item href="/posts/Crypto">Crypto</NavDropdown.Item>
                </NavDropdown>
              </Col>
              <Col><Nav.Link href="/posts/Essay">Essay</Nav.Link></Col>
              <Col><Nav.Link href="/posts/Fiction">Fiction</Nav.Link></Col>
            </Row>
          </Nav>
          <Nav className="justify-content-end">
            {auth.user
              && <>
                  {auth.user.isAdmin 
                    && <Nav.Link href="/admin">Admin Control</Nav.Link>}
                  {auth.user.isWriter 
                    && <>
                      <Nav.Link href="/drafts">Drafts</Nav.Link>
                      <Nav.Link href="/write">Write</Nav.Link>
                    </>}
                </>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navi;