import React from "react";
import { 
  Container,
  Row,
} from 'react-bootstrap';
import Navi from '../NavigationBar';
import Banner from '../Banner';
import Footer from '../Footer';
import CreateType from '../forms/CreateType';

const Admin = ()=>{
  return(
    <div className="base">
      <Banner/>
      <Navi/>
      <Container style = {{marginTop: "50px"}}>
        <div className="title">
          <h1>Admin Control Panel</h1>
        </div>
        <Row md={2} style={{marginTop: "50px"}}>
          <CreateType/>
        </Row>
      </Container>
      <Footer/>
    </div>)
}

export default Admin;