import {
  useState,
} from "react";
import { 
  Container, 
  Button,
  Row,
  Col,
} from "react-bootstrap";
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import {
  useQuery, useMutation,
} from '@apollo/client';

import {useAppSelector} from '../../hooks';
import {POST} from '../../graphql/queries/post.query';
import {DELETE_POST} from '../../graphql/mutations/post.mutation';
import PostRender from '../PostRender';
import Banner from '../Banner';
import Navi from '../NavigationBar';
import Footer from '../Footer';
import NotFound from './NotFound';

const Post=()=>{
  const auth = useAppSelector((state)=>state.auth);
  const [deletePost,] = useMutation(DELETE_POST);

  const {slug} = useParams<{slug: string}>();
  const [id, setId] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const {data, loading} = useQuery(POST, {
    variables: {
      input: {
        slug: slug
      }
    },
    onCompleted: (d: {post: any})=>{
      if(d.post) {
        setBody(d.post.body); setTitle(d.post.title); setId(d.post.id);
      }
    }
  })

  const history = useHistory();

  const routeChange=()=>{
    const path = `/edit/${id}`;
    history.push(path);
  }

  const delete_ = async(e: React.SyntheticEvent)=>{
    e.preventDefault();
    try{
      await deletePost({
        variables: {
          id
        }
      });
      window.alert('Success!!!');
      window.location.href=`/`;
    } catch(err: any) {
      window.alert(err.message);
    }
  }

  if(loading) return null;
  if(data&&data.post)
    return (
      <div className="base">
        <Banner/>
        <Navi/> 
        <Container style = {{marginTop: "50px"}}>       
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="created-at" style={{fontSize: "15px"}}>
            <>{(new Date(data.post.createdAt)).toLocaleDateString('en-US', { 
                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' 
              })}</>
          </div>
          <hr/>
          <Row>
            <PostRender body={body}/>
          </Row>
          <hr/>
          <div className="updated-at" style={{fontSize: "15px"}}>
            <>Last update {(new Date(data.post.updatedAt)).toLocaleDateString('en-US', {
              weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
            })}</>
          </div>
          {auth&&auth.user&&auth.user.id===data.post.writer.id&&
          <Row style={{marginTop: "20px"}}>
            <Col>
              <Button style={{float: 'right'}} onClick={routeChange}>Edit</Button>
              <Button variant="danger" style={{float: 'right', marginRight: "5px"}}
                onClick={delete_}>Delete</Button>
            </Col>
          </Row>}
        </Container>
        <Footer/>
      </div>);
  return <NotFound/>;
}

export default Post;